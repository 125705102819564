import React, {useEffect, useRef} from 'react';
import '../../hooks/useAuth';
import {useNavigate} from "react-router-dom";

import SignOutButton from "../sidebar/SignOutButton";
import { Box, Button, Typography } from '@mui/material';
import ReloadDataButton from "../sidebar/ReloadDataButton";
const AdminDashboard = (props) => {
    // get current year
    let year = new Date().getFullYear();
    const navigate = useNavigate();

    // console.log(props.userData);
    let name = "?";
    if(props.userData !== null){
        if(props.userData.personData !== null && props.userData.personData !== undefined){
            if(props.userData.personData.first_name !== null){
                name = props.userData.personData.first_name
            }
            else if(props.userData.username){
                name = props.userData.username
            }
        }
        else if(props.userData.username){
            name = props.userData.username
        }
    }

    const gotoStudents = () => {
        navigate('/auth/admin/students');
    };
    const gotoProjects = () => {
        navigate('/auth/admin/projects');
    };
    const gotoJudges = () => {
        navigate('/auth/admin/judges');
    };

    const renderTable = () => {
        const table = [];
        for (let row = 0; row < 1; row++) {
            const tableRow = [];
            for (let col = 0; col < 10; col++) {
                tableRow.push(
                    <td key={col}>
                        <button
                            style={{
                                width: '50px',
                                height: '50px',
                                display: 'block',
                                margin: '5px',
                            }}
                        >
                            {row * 10 + col + 1}
                        </button>
                    </td>
                );
            }
            table.push(<tr key={row}>{tableRow}</tr>);
        }
        return table;
    };

    return (
        <div>
            <Box sx={{ display: 'flex', height: '100vh' }}>
                {/* Left Sidebar */}
                <Box
                    sx={{
                        width: '15%',
                        backgroundColor: '#f4f4f4',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                        borderRight: '1px solid #ccc',
                    }}>
                    {/* Sidebar content goes here */}
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Hello, {name}
                    </Typography>
                    <SignOutButton variant="contained" sx={{ mb: 1 }} setUserData={props.setUserData}/>
                    <ReloadDataButton setUserData={props.setUserData}/>
                    <Button variant="contained" sx={{ mb: 1 }}>Item 2</Button>
                    <Button variant="contained" sx={{ mb: 1 }}>Item 3</Button>
                    <Button variant="contained" sx={{ mb: 1 }}>Item 4</Button>
                </Box>

                {/* Main Content */}
                <Box sx={{ width: '70%', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* Title and Logo */}
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                        <img src="prsflogo.png" alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
                        <Typography variant="h4">Peel Region Science Fair, {year}</Typography>
                    </Box>

                    {/* Buttons Layout (2x4 grid) */}
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gridGap: '20px',
                        }}
                    >
                        {/*Reroute to /auth/admin/students when clicking button*/}

                        <Button variant="contained" sx={{minWidth: '250px', minHeight: '60px'}} onClick={gotoStudents}>Students
                            Registered:</Button>
                        <Button variant="contained" sx={{minWidth: '250px', minHeight: '60px'}}>Checked-In:</Button>
                        <Button variant="contained" sx={{minWidth: '250px', minHeight: '60px'}}
                                onClick={gotoProjects}>Projects: </Button>
                        <Button variant="contained" sx={{minWidth: '250px', minHeight: '60px'}}>Evaluations
                            Completed</Button>
                        <Button variant="contained" sx={{minWidth: '250px', minHeight: '60px'}}>List Active
                            Rubrics</Button>
                        <Button variant="contained" sx={{minWidth: '250px', minHeight: '60px'}}>View/Edit Rubric
                            Templates</Button>
                        <Button variant="contained" sx={{minWidth: '250px', minHeight: '60px'}}
                                onClick={gotoJudges}>Judges: </Button>

                        {/*<Button variant="contained" sx={{ minWidth: '250px', minHeight: '60px' }}>Button 8</Button>*/}
                        <table>
                            <tbody>{renderTable()}</tbody>
                        </table>
                    </Box>
                </Box>

                {/* Right Sidebar */}
                <Box
                    sx={{
                        width: '15%',
                        backgroundColor: '#f0f0f0',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                        borderLeft: '1px solid #ccc',
                    }}
                >
                    {/* Sidebar content goes here */}
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Admin Dashboard
                    </Typography>
                    <Button variant="contained" sx={{ mb: 1 }}>Item A</Button>
                    <Button variant="contained" sx={{ mb: 1 }}>Item B</Button>
                    <Button variant="contained" sx={{ mb: 1 }}>Item C</Button>
                    <Button variant="contained" sx={{ mb: 1 }}>Item D</Button>
                </Box>
            </Box>
        </div>
    );
}

export default AdminDashboard;