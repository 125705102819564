import React from 'react';
import '../../hooks/useAuth';

import SignOutButton from "../sidebar/SignOutButton";
import {Box, Button, Typography} from '@mui/material';
import ReloadDataButton from "../sidebar/ReloadDataButton";
import JudgeSchedule from "./JudgeSchedule";
import LeftJudgeSidebar from "../sidebar/LeftJudgeSidebar";
import RightJudgeSidebar from "../sidebar/RightJudgeSidebar";
import '../../styles/dashboard.css';

export const getName = (userData) => {
    let name = "?";
    if(userData !== null){
        if(userData.personData !== null && userData.personData !== undefined){
            if(userData.personData.first_name !== null){
                name = userData.personData.first_name
            }
            else if(userData.username){
                name = userData.username
            }
        }
        else if(userData.username){
            name = userData.username
        }

    }
    return name
}

const JudgeDashboard = (props) => {

    if(props.userData === null){
        return (
            <div>error loading user data!</div>
        );
    }
    // get current year
    let year = new Date().getFullYear();

    // console.log(props.userData);
    let name = getName(props.userData)
    let rubrics = []
    if(props.userData !== null){
        rubrics = props.userData.rubricData
    }
    return (
        <div className="container">
            <div className="left-sidebar">
                <LeftJudgeSidebar name={name} setUserData={props.setUserData} />
            </div>

            <div className="main-content">
                <Box sx={{  display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                        <img src={"/prsflogo.png"} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
                        <Typography variant="h4">Peel Region Science Fair, {year}</Typography>
                    </Box>
                    <Typography>Your Schedule:</Typography>
                    <JudgeSchedule rubrics={rubrics.rubrics} />
                </Box>
            </div>

            <div className="right-sidebar">
                <RightJudgeSidebar userData={props.userData} setUserData={props.setUserData} />
            </div>
        </div>
    );
};

export default JudgeDashboard;