import React from 'react';
import {useNavigate} from "react-router-dom";
import {getName} from "../judge/JudgeDashboard";

import LeftJudgeSidebar from "../sidebar/LeftJudgeSidebar";
import {loadUserDataComplete} from "../../api/api";
import RightJudgeSidebar from "../sidebar/RightJudgeSidebar";
import {Box, Button, Typography} from "@mui/material";
import RubricView from "./RubricView";
import '../../styles/dashboard.css';

const Rubric = (props) => {
    const navigate = useNavigate();
    let year = new Date().getFullYear();
    let name = getName(props.userData)
    const [loadedData, setLoadedData] = React.useState(false);
    let admin = false;
    let rubrics = []
    if(props.userData === null || props.userData === undefined){
        // only run once by using useRef
        if(loadedData === false){
            setLoadedData(true);
            loadUserDataComplete(props.setUserData)
        }

        return (
            <div>
                <h1>Rubric</h1>
                <p>Loading...</p>
                <p>If you are not logged in you should be redirected, you will not be able to access this page logged out.</p>
            </div>
        );
    }
    else{
        admin = props.userData.is_admin > 0;
        rubrics = props.userData.rubricData.rubrics
    }

    // get rubric from url, it comes after last slash
    const url = window.location.href;
    const project_id = url.substring(url.lastIndexOf('/') + 1);
    const relevant_rubric = rubrics.filter((rubric) => String(rubric.project_id).toString() === project_id)[0]

    // console.log("Relevant Rubric:")
    // console.log(relevant_rubric)

    const navToDashboard = () => {
        navigate('/auth/dashboard/');
    };

    if(relevant_rubric === undefined){
        return (<>
            <p>Error loading rubric page, likely due to a bad URL</p>
            <Button variant="contained" sx={{ mb: 1 }} onClick={navToDashboard}>Back to Dashboard</Button>
        </>)
    }
    return (
        <div>
            <Box className="container" sx={{display: 'flex', height: '100vh'}}>
                {/* Left Sidebar */}
                <div className="left-sidebar">
                    {!admin ? <LeftJudgeSidebar name={name} setUserData={props.setUserData}/> : <p>admin</p>}
                </div>

                <Box className="main-content" sx-={{ flexDirection: "column", alignItems: "center"}}>
                    {/* Title and Logo */}
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 4}}>
                        <img src={"/prsflogo.png"} alt="Logo" style={{height: '40px', marginRight: '10px'}}/>
                        <Typography variant="h4">Peel Region Science Fair, {year}</Typography>
                    </Box>

                    <Typography variant={"h5"}>Grade Project</Typography>
                    <Typography>Project: {relevant_rubric.project_name} (#{relevant_rubric.project_id})</Typography>
                    <RubricView  userData={props.userData} rubric={relevant_rubric}/>

                </Box>
                <div className="right-sidebar">
                    {!admin ? <RightJudgeSidebar name={name} userData={props.userData} setUserData={props.setUserData}/> :
                        <p>admin</p>}
                </div>

            </Box>

        </div>
    );
};

export default Rubric;