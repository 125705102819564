import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import SignOutButton from "./SignOutButton";
import ReloadDataButton from "./ReloadDataButton";
import DashboardButton from "./DashboardButton";
const LeftJudgeSidebar = (props) => {

    return (
        <Box
            sx={{
                height: '100%',
                width:'100%',
                display:'flex',
                backgroundColor: '#f4f4f4',
                flexDirection: 'column',
                padding: '10px',
                borderRight: '1px solid #ccc',
            }}
        >
            <Typography variant="h6" sx={{ mb: 2 }}>
                Hello, {props.name}
            </Typography>
            <SignOutButton style={{width:'90%'}} variant="contained" sx={{ mb: 1 }} setUserData={props.setUserData}/>
            <ReloadDataButton style={{width:'90%'}} setUserData={props.setUserData}/>
            <DashboardButton style={{width: '90%'}} sx={{ mb: 1 }}/>
            {/*<Button style={{width:'90%'}} variant="contained" sx={{ mb: 1 }}>Item 3</Button>*/}
            {/*<Button style={{width:'90%'}} variant="contained" sx={{ mb: 1 }}>Item 4</Button>*/}
        </Box>
    );
}

export default LeftJudgeSidebar;