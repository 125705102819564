import LoginForm from "./components/login/LoginForm";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignUpForm from './components/signup/SignupForm';
import Dashboard from './components/dashboards/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import useAuth from "./hooks/useAuth";
import Home from "./components/home";
import {useState} from "react";
import AdminRoute from "./components/AdminRoute";
import Judges from "./components/auth/judges";
import Students from "./components/auth/students";
import Projects from "./components/auth/projects";
import Rubric from "./components/rubric/rubric";

const App = () => {
  const { isAuthenticated } = useAuth();
  const [userData, setUserData] = useState(null);
  return (
    <Router>
      <Routes>
          {/* Public routes */}
          <Route path="/login" element={<LoginForm userData={userData} setUserData={setUserData} />} />
          <Route path="/signup" element={<SignUpForm />} />
          <Route path="/" element={<Home />} />

          {/* Admin Route */}
          <Route path="/auth/admin/*" element={<AdminRoute userData={userData} setUserData={setUserData} />}>
              <Route path="dashboard" element={<Dashboard userData={userData} setUserData={setUserData} />} />
              <Route path="judges" element={<Judges userData={userData}/>} />
              <Route path="students" element={<Students userData={userData}/>} />
              <Route path="projects" element={<Projects userData={userData}/>} />
              <Route path="rubric/*" element={<Rubric userData={userData} setUserData={setUserData}/>} />

          </Route>

          {/* Protected routes for auth/* */}
          <Route path="/auth/" element={<ProtectedRoute />}>
              <Route path="dashboard" element={<Dashboard userData={userData} setUserData={setUserData} />} />
              <Route path="rubric/*" element={<Rubric userData={userData} setUserData={setUserData}/>} />

          </Route>

          <Route path="/unauthorized" element={<h1>Unauthorized</h1>} />

          {/* Fallback route */}
          <Route path="*" element={<Navigate to={isAuthenticated ? "/auth/dashboard" : "/login"} />} />
      </Routes>
    </Router>
  );
};
export default App;