import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControlLabel,
    Stepper,
    Step,
    StepLabel,
    Radio,
    RadioGroup,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {massSetCategories, updateRubric} from "../../api/api";
import RubricCategory from "./RubricCategory";

const RubricView = (props) => {
    const [publicFeedback, setPublicFeedback] = useState('');
    const [privateFeedback, setPrivateFeedback] = useState('');
    const [recommend, setRecommend] = useState(false);
    const [defaultRecommendValue, setDefaultRecommendValue] = useState('not_recommend');
    const [submittedRubric, setSubmittedRubric] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();

    const steps = ['Step 1', 'Step 2', 'Step 3'];

    useEffect(() => {
        if (props.rubric) {
            setPublicFeedback(props.rubric.public_feedback || '');
            setPrivateFeedback(props.rubric.private_feedback || '');
            setRecommend((props.rubric.recommended === 1) || false);
            setDefaultRecommendValue((props.rubric.recommended === 1) ? 'recommend' : 'not_recommend');
        }
    }, [props.rubric]);

    if (!props.rubric) {
        return (
            <div>
                Rubric is not defined - this could mean that you do not have the
                permissions to edit it!
            </div>
        );
    }

    const saveFeedback = async () => {
        if (submittedRubric) return;
        setSubmittedRubric(true);
        setLoading(true);

        // collect each category's score
        let out = [];

        let valid = true;
        for (let key in props.rubric.categories) {
            // iterate through each entry in the category
            let category = props.rubric.categories[key];
            for (let entry in category.children) {
                let score = category.children[entry].score;
                if (score === 0){
                    valid = false;
                }
                out.push({"id": category.children[entry].id, "score": score});
            }
        }

        await massSetCategories(props.rubric.id, out);

        await updateRubric(publicFeedback, privateFeedback, recommend, props.rubric.id, valid);


        props.rubric.public_feedback = publicFeedback;
        props.rubric.private_feedback = privateFeedback;
        props.rubric.recommended = recommend ? 1 : 0;
        props.rubric.completed = 1;

        setLoading(false);
        if(valid) {
            navigate('/auth/dashboard', { state: { message: 'Your submission was received!', severity: 'success' } });
        }
        else{
            navigate('/auth/dashboard', { state: { message: 'Your submission was received but is incomplete...', severity: 'warning' } });
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) =>
            prevActiveStep < steps.length - 1 ? prevActiveStep + 1 : prevActiveStep
        );
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) =>
            prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep
        );
    };

    const quit = () => {
        setOpenDialog(true);
    }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate('/auth/dashboard', { state: { message: 'You did not submit anything', severity: 'warning' } });
        }
    }

    const extractRubricData = (dictData, showMode = false) => {
        const sortedEntries = Object.entries(dictData).sort(([keyA, valueA], [keyB, valueB]) => {
            if (valueA.name === "Rubric") return 1;
            if (valueB.name === "Rubric") return -1;
            return 0;
        });

        return sortedEntries.map(([key, value]) => {
            return (
                <RubricCategory
                    key={key}
                    data={value}
                    showMode={showMode}
                />
            );
        });
    }

    const getStepOne = () => {
        return (
            <div>
                {extractRubricData(props.rubric.categories)}
            </div>
        );
    }

    const getStepTwo = () => {
        return (
            <div sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Private Feedback
                </Typography>
                <Typography style={{ color: '#4b4b4b' }} variant="body2" sx={{ mt: 1 }}>
                    Only you and PRSF coordinators can see this feedback.
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={privateFeedback}
                    onChange={(e) => setPrivateFeedback(e.target.value)}
                    sx={{ mt: 1 }}
                />
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Public Feedback
                </Typography>
                <Typography style={{ color: '#4b4b4b' }} variant="body2" sx={{ mt: 1 }}>
                    The student will be presented with this feedback - please be courteous.
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={publicFeedback}
                    onChange={(e) => setPublicFeedback(e.target.value)}
                    sx={{ mt: 1 }}
                />
                <Typography sx={{ mt: 2 }} variant="h6">Recommendation</Typography>
                <Typography style={{ color: '#4b4b4b' }} variant="body2">
                    Do you wish to recommend this project to represent Peel at the Canada Wide Science Fair?
                </Typography>
                <RadioGroup
                    defaultValue={defaultRecommendValue ?? 'not_recommend'}
                    onChange={(e) => setRecommend(e.target.value === 'recommend')}
                >
                    <FormControlLabel
                        value="recommend"
                        control={<Radio color="primary" />}
                        label="I recommend this project."
                    />
                    <FormControlLabel
                        value="not_recommend"
                        control={<Radio color="primary" />}
                        label="I do NOT recommend this project."
                    />
                </RadioGroup>
            </div>
        );
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return getStepOne();
            case 1:
                return getStepTwo();
            case 2:
                return (
                    <div>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Review
                        </Typography>
                        {extractRubricData(props.rubric.categories, true)}
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Private Feedback
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            {privateFeedback}
                        </Typography>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Public Feedback
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            {publicFeedback}
                        </Typography>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Recommendation
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            {recommend ? 'I recommend this project to represent Peel at the Canada Wide Science Fair.' : 'I do not recommend this project to represent Peel at the Canada Wide Science Fair.'}
                        </Typography>

                    </div>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <div>
            <Stepper activeStep={activeStep} sx={{ mt: 2, mb: 2 }}>
                <Step>
                    <StepLabel>Complete Rubric</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Student Feedback</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Review</StepLabel>
                </Step>
            </Stepper>
            {getStepContent(activeStep)}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2}}>
                {(activeStep > 0)?
                    <Button
                        style={{ color: "#FFFFFF", backgroundColor: "#383838" }}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    : <Button
                        style={{ color: "#FFFFFF", backgroundColor: "#910000" }}
                        onClick={quit}
                        sx={{ mr: 1 }}
                    >
                        Exit
                    </Button>}
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep === steps.length - 1 ? (
                    loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            disabled={submittedRubric}
                            style={{ color: "#FFFFFF", backgroundColor: "#12824C" }}
                            onClick={saveFeedback}
                        >
                            Submit
                        </Button>
                    )
                ) : (
                    <Button
                        style={{ color: "#FFFFFF", backgroundColor: "#383838" }}
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                )}
            </Box>
            {activeStep === steps.length - 1 && (
                <Typography
                    style={{ textAlign: "center", color: "#4B4B4BFF", marginTop: "5px", marginBottom: "25px" }}
                    variant="body1"
                >
                    You can always modify your submission before the deadline.
                </Typography>
            )}
            <Box sx={{ height: '100px' }} />
            <Dialog
                open={openDialog}
                onClose={() => handleDialogClose(false)}
            >
                <DialogTitle>Confirm Exit</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to quit? Any unsaved changes will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RubricView;