import React from 'react';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";


const Projects = () => {
    const navigate = useNavigate();
    return (
        <div>
            <h1>Projects: </h1>

        </div>
    );
};

export default Projects;