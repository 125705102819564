import React, { createContext, useState, useEffect } from 'react';
import { isTokenExpired } from '../api/auth';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!isTokenExpired());

  useEffect(() => {
    const checkAuth = () => {
      const expired = isTokenExpired();
      setIsAuthenticated(!expired);
      if (expired) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiry');
      }
    };

    checkAuth();

    const intervalId = setInterval(checkAuth, 15000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};