import React from 'react';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";


const Students = () => {
    const navigate = useNavigate();
    return (
        <div>
            <h1>Students: </h1>

        </div>
    );
};

export default Students;