import axios from './axios';

export const get_regions = async () => {
  try {
    const response = await axios.get('/valid_regions');
    // console.log(response)
    // Capitalize the regions
    response.data.res = response.data.res.map(region => region.charAt(0).toUpperCase() + region.slice(1));
    return response.data.res;
  } catch (error) {
    throw new Error('Error loading regions' || error.message.data);
  }
};


export const getUserData = async () => {
  try {
    const response = await axios.get('/user/me');
    return response.data;
  } catch (error) {
    console.error("Error loading user data")
    return null;
  }
};

export const getPersonData = async (person_id) => {
  try {
    const response = await axios.get('/person/get/' + person_id);
    return response.data;
  } catch (error) {
    console.error("Error loading person data")
    return null;
  }
};

export const getTeamMembers = async (project_id) => {
    try {
        const response = await axios.get('/team/project/' + project_id);
        return response.data;
    } catch (error) {
      console.error("Error loading team members")
      return null;
    }
}

export const getJudgeRubricData = async (person_id) => {
  try {
    const response = await axios.get('/rubric/judge/' + person_id);
    let rubric_data = response.data;
    if(rubric_data === null){
      console.error("Error loading rubric data")
      return null;
    }
    for (let i = 0; i < rubric_data.rubrics.length; i++){
      const team = await getTeamMembers(rubric_data.rubrics[i].project_id);
      if(team === null)
        return null;
      rubric_data.rubrics[i].team_members = team;
    }
    return rubric_data;
  } catch (error) {
    console.error("Error loading judge rubric data")
    return null;
  }
};


export const loadUserDataComplete = async(setUserData) => {
  const userData = await getUserData();
  if(userData !== null){

    if (userData.person_id !== null){
      const personData = await getPersonData(userData.person_id);
      if(personData !== null){
        userData.personData = personData;
        if(userData.personData.type === "judge"){
          const rubricData = await getJudgeRubricData(userData.person_id);
          if(rubricData !== null)
            userData.rubricData = rubricData
          else{
            userData.rubricData = null;
          }
        }
      }
      else {
        userData.personData = null;
        userData.rubricData = null;
      }
    }
    else {
      userData.personData = null;
      userData.rubricData = null;
    }
  }
  if(userData === null){
    console.error('Severe error attempting to load user data');
    // navigate('/login', { state: { message: 'Severe error attempting to load user data', severity: 'error' } });
  }
  // console.log(userData);
  setUserData(userData);
}

export const updateRubric = async (public_feedback, private_feedback, recommend, rubric_id, is_valid = true) => {
    try {
        const response = await axios.post('/rubric/update/by_id/' + rubric_id, {
          pub_feed: public_feedback,
          priv_feed: private_feedback,
          recommended: recommend,
          completed: (is_valid? 1 : 0),
        });
        return response.data;
    } catch (error) {
        console.error("Error updating rubric")
        return null;
    }
}

export const massSetCategories = async (rubric_id, data_arr) => {
  try {
    const response = await axios.post('/rubric/' + rubric_id + '/score/mass_update', {
      rubric_id: rubric_id,
      scores: data_arr,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating rubric")
    return null;
  }
}